<template>
<div>
  <article>
    <section>
      <b-breadcrumb class="bg-light m-0 px-md-0">
        <b-breadcrumb-item :to="{ name: 'home' }">Home</b-breadcrumb-item>
        <b-breadcrumb-item active>お問い合わせ完了</b-breadcrumb-item>
      </b-breadcrumb>
      <h1 class="border rounded-lg bg-indigo text-light w-100 p-2">お問い合わせ完了</h1>
      <p class="p-2">お問い合わせが完了しました。<br />
      内容を確認させていただき、返信させていただきますのでしばらくお待ちください。</p>
    </section>
  </article>
</div>
</template>

<script>
  export default {
    props:{
      settings: Object,
    },
    data() {
      return {
        item: {},
      }
    },
    created() {
      /* META */
      const type = 'article'
      const title = "お問い合わせ完了 | " + this.settings.site_title
      const description = "お問い合わせ完了ページ"
      const canonical = this.settings.site_url + "/contact/complete"
      document.title = title;
      document.querySelector("meta[name='description']")
        .setAttribute('content', description)
      document.querySelector("meta[property='og:type']")
        .setAttribute('content', type)
      document.querySelector("meta[property='og:url']")
        .setAttribute('content', canonical)
      document.querySelector("meta[property='og:title']")
        .setAttribute('content', title)
      document.querySelector("meta[property='og:description']")
        .setAttribute('content', description)
      document.querySelector("link[rel='canonical']")
        .setAttribute('href', canonical)
    },
    methods: {

    }
  }
</script>

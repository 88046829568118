<template>
<div>
  <article>
    <section>
      <b-breadcrumb class="bg-light m-0 px-md-0">
        <b-breadcrumb-item :to="{ name: 'home' }">Home</b-breadcrumb-item>
        <b-breadcrumb-item active>お問い合わせ</b-breadcrumb-item>
      </b-breadcrumb>
      <h1 class="border rounded-lg bg-indigo text-light w-100 p-2">お問い合わせ</h1>

  <b-overlay :show="busy" rounded="sm">

    <b-form ref="form" class="p-2">
      <b-form-group
        label="お名前"
        label-for="name-input"
      >
        <b-form-input
          id="name-input"
          v-model="form.name"
          :state="validation.name"
          type="text"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation.name">
          <ul v-for="(item) in errors.name" :key="item.index">
            <li>{{ item }}</li>
          </ul>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="メールアドレス"
        label-for="email-input"
      >
        <b-form-input
          id="email-input"
          v-model="form.email"
          :state="validation.email"
          type="email"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation.email">
          <ul v-for="(item) in errors.email" :key="item.index">
            <li>{{ item }}</li>
          </ul>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="件名"
        label-for="subject-input"
      >
        <b-form-input
          id="subject-input"
          v-model="form.subject"
          :state="validation.subject"
          type="text"
        ></b-form-input>
        <b-form-invalid-feedback :state="validation.subject">
          <ul v-for="(item) in errors.subject" :key="item.index">
            <li>{{ item }}</li>
          </ul>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="内容"
        label-for="comment-input"
      >
        <b-form-textarea
          id="comment-input"
          rows="8"
          v-model="form.comment"
          :state="validation.comment"
        ></b-form-textarea>
        <b-form-invalid-feedback :state="validation.comment">
          <ul v-for="(item) in errors.comment" :key="item.index">
            <li>{{ item }}</li>
          </ul>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-button variant="primary" v-b-modal.modal-confirm>送信</b-button>
    </b-form>

  </b-overlay>

      <b-modal id="modal-confirm" title="送信確認" button-size="lg" v-model="modal">
        <p class="my-2">送信してもよろしいですか？</p>
          <template #modal-footer>
          <b-button size="sm" variant="secondary" @click="cancel">
            Cancel
          </b-button>
          <b-button size="sm" variant="primary" @click="post">
            OK
          </b-button>
          </template>
      </b-modal>
    </section>
  </article>
</div>
</template>

<script>
import Vue from 'vue'
import { VueReCaptcha } from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, {
  siteKey: '6LeFdi4aAAAAAKAN68GlibxvHUuOMVLSOJ9aZ1HT',
  loaderOptions: {
    autoHideBadge: true
  }
})
  export default {
    props:{
      settings: Object,
    },
    data() {
      return {
        form: {
          name: '',
          email: '',
          subject: '',
          comment: '',
        },
        errors: {},
        validation: {},
        busy: false,
        modal: false,
      }
    },
    created() {
      /* META */
      const type = 'article'
      const title = "お問い合わせ | " + this.settings.site_title
      const description = "お問い合わせページ。"
      const canonical = this.settings.site_url + "/contact"
      document.title = title;
      document.querySelector("meta[name='description']")
        .setAttribute('content', description)
      document.querySelector("meta[property='og:type']")
        .setAttribute('content', type)
      document.querySelector("meta[property='og:url']")
        .setAttribute('content', canonical)
      document.querySelector("meta[property='og:title']")
        .setAttribute('content', title)
      document.querySelector("meta[property='og:description']")
        .setAttribute('content', description)
      document.querySelector("link[rel='canonical']")
        .setAttribute('href', canonical)
    },
    methods: {
      async post(evt) {
        evt.preventDefault()

        // reCAPTCHA v3
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('login')

        this.modal = false
        this.busy = true
        await axios.post('/api/contact', this.form)
        .then((response) => {
          const data = response.data
          if (data.status == 400) {
            // Validation False
            Object.keys(data.errors).map(key => {
              this.validation[key] = false  
            });
            this.errors = data.errors
          } else {
            if (!data.result) {
              // ServiceError
            } else {
              // Success
              this.$router.push({ name: 'contactComplete' })
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.busy = false
        })
      },
      cancel() {
        this.modal = false
      }
    }
  }
</script>

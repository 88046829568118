<template>
<div>
  <article>
    <section>
      <b-breadcrumb class="bg-light m-0 px-md-0">
        <b-breadcrumb-item :to="{ name: 'home' }">Home</b-breadcrumb-item>
        <b-breadcrumb-item active>プライバシーポリシー</b-breadcrumb-item>
      </b-breadcrumb>
      <h1 class="border rounded-lg bg-indigo text-light w-100 p-2">プライバシーポリシー</h1>

<div class="markdown-body">

<div>
本プライバシーポリシーは、「Scramblenote」(https://scramblenote.com）（以下、「当サイト」とします。)の各種 サービスにおいて、当サイトの訪問者（以下、「訪問者」とします。）の個人情報を取り扱う際に当サイトが遵守する方針です。

<h2>個人情報の取得と利用</h2>
当サイトでは、メールでのお問い合わせの際に、名前（ハンドルネーム）、メールアドレス等の個人情報をご登録いただく場合がございます。
これらの個人情報は質問に対する回答や必要な情報を電子メール等でご連絡する場合に利用させていただくものでそれらの目的以外では利用いたしません。<br />
<br />
【Google Analyticsについて】<br />
利用状況を把握するためGoogleが提供している分析ツール「Google Analytics」を利用しています。（Google Analytics のプライバシーとセキュリティについては<a rel="noreferrer noopener" href="https://support.google.com/analytics/topic/2919631?hl=ja" target="_blank" class="blue--text">コチラ</a>を参照してください）
このGoogle Analyticsはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。

<h2>個人情報の保管</h2>
取得した個人情報について不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、セキュリティシステムの維持等の必要な措置を講じ、安全対策を実施し個人情報の厳重な管理を行ないます。

<h2>個人情報の開示</h2>
次に該当する場合を除いて、取得した個人情報を第三者に開示することはありません。
<ul>
  <li>提供者の同意がある場合</li>
  <li>法令等に基づき開示が必要となる場合</li>
</ul>

提供者ご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、対応させていただきます。

<h2>免責事項</h2>
当サイトで掲載している画像の著作権・肖像権等は各権利所有者に帰属致します。権利を侵害する目的ではござ いません。記事の内容や掲載画像等に問題がございましたら、各権利所有者様ご本人が直接お問い合わせください。確認後、対応させて頂きます。
<br />
当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
<br />
当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が 入り込んだり、情報が古くなっていることもございます。 当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。

<h2>著作権について</h2>
当サイトに掲載されている情報についての著作権は放棄しておりません。当サイトに存在する、文章・画像・動 画等の著作物の情報を無断転載を禁止いたします。

<h2>プライバシーポリシーの変更について</h2>
当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその 改善に努めます。修正された最新のプライバシーポリシーは常に本ページにて開示されます。

<h2>お問い合わせ</h2>
当サイトの個人情報の取扱に関するお問い合せは
<router-link :to="{ name: 'contact' }" class="blue--text">
コチラ
</router-link>
からご連絡ください。

<div class="text-right">
以上<br />
2021年01月01日制定
</div>
</div>

</div>

    </section>
  </article>

</div>
</template>

<script>
  export default {
    props:{
      settings: Object,
    },
    data() {
      return {
        item: {},
      }
    },
    created() {
      /* META */
      const type = 'article'
      const title = "プライバシーポリシー | " + this.settings.site_title
      const description = "プライバシーポリシーのページ。"
      const canonical = this.settings.site_url + "/privacy/"
      document.title = title;
      document.querySelector("meta[name='description']")
        .setAttribute('content', description)
      document.querySelector("meta[property='og:type']")
        .setAttribute('content', type)
      document.querySelector("meta[property='og:url']")
        .setAttribute('content', canonical)
      document.querySelector("meta[property='og:title']")
        .setAttribute('content', title)
      document.querySelector("meta[property='og:description']")
        .setAttribute('content', description)
      document.querySelector("link[rel='canonical']")
        .setAttribute('href', canonical)
    },
    methods: {

    }
  }
</script>

<template>
  <div id="aside" class="sidebar-wrapper bg-primary">
    <aside>
      <b-list-group flush>
        <b-list-group-item variant="primary" to="/user/category" active-class="active">カテゴリ</b-list-group-item>
        <b-list-group-item variant="primary" to="/user/tag" active-class="active">タグ</b-list-group-item>
        <b-list-group-item variant="primary" to="/user/article" active-class="active">投稿</b-list-group-item>
        <b-list-group-item></b-list-group-item>
        <b-list-group-item variant="primary" to="/user/profile" exact exact-active-class="active">ユーザ情報</b-list-group-item>
        <b-list-group-item variant="secondary" to="/user/withdrawal" exact exact-active-class="active">退会</b-list-group-item>
        <b-list-group-item></b-list-group-item>
        <b-list-group-item variant="primary" to="/user/Article1" exact exact-active-class="active">Tiptap</b-list-group-item>
        <b-list-group-item variant="primary" to="/user/Article2" exact exact-active-class="active">Quill</b-list-group-item>
      </b-list-group>
    </aside>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    methods: {

    }
  }
</script>

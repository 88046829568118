<template>
<div>
  <article>
    <section>
      <b-breadcrumb class="bg-light m-0 px-md-0">
        <b-breadcrumb-item :to="{ name: 'home' }">Home</b-breadcrumb-item>
      </b-breadcrumb>
      <h1>Not Found</h1>
      <p>お探しの記事は残念ながらございません。</p>
    </section>
  </article>
</div>
</template>

<script>
  export default {
    mounted() {
    }
  }
</script>

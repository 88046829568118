require('./bootstrap');
import Vue from 'vue'
import router from './app_router';
import store from './store/index';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.component('app-component',require('./components/App.vue').default);
Vue.component('app-menu-component',require('./components/App/Menu.vue').default);

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

// URL変更時に呼び出される
router.afterEach((to, from) => {
  if (typeof gtag === 'function') {
    gtag('config', 'UA-187336292-1', {'page_path': to.path});
  }
})

const app = new Vue({
    el: '#app',
    router,
    store,
});
<template>
<div>

<div id="header" class="container-fluid p-0">
  <header>
    <nav class="navbar navbar-light bg-light">
      <div class="container p-0">
        <b-button v-b-toggle.leftmenu id="leftbtn" class="bg-indigo border">
          <b-icon icon="list"></b-icon>
        </b-button>
        <router-link :to="{ name: 'home' }">
          <div v-if="$route.name == 'home'"><h1 class="navbar-brand my-0 py-0">ScrambleNote</h1></div>
          <div class="h1 navbar-brand my-0 py-0" v-else>ScrambleNote</div>
        </router-link>
        <b-button id="rightbtn" @click="rightToggled=!rightToggled" class="bg-indigo text-dark border-0">
          <b-icon icon="list"></b-icon>
        </b-button>
      </div>
    </nav>

    <nav class="navbar navbar-light p-0 mx-auto">
      <div class="container p-0">
        <b-collapse id="leftmenu" class="text-center w-100" v-model="leftToggled">
          <b-list-group horizontal="md" id="category" class=" w-100">
            <b-list-group-item
              v-for="category in settings.category" :key="category.id"
              :to="'/category/'+category.slug"
              active-class="menu-item-active"
              class="menu-item squared"
              :id="'category-'+category.slug"
            >{{ category.name }}</b-list-group-item>
          </b-list-group>
        </b-collapse>
      </div>
    </nav>

  </header>
</div>

<div class="d-flex container p-0" id="wrapper" :class="{'toggled': rightToggled}">

  <div id="main" class="mx-sm-3">
    <div id="cover" @click="rightToggled=!rightToggled"></div>
    <main>
        <router-view :settings="settings" @result="response"></router-view>
    </main>
  </div>

  <div id="aside">
    <aside>
      <b-list-group class="">
        <b-list-group-item class="squared bg-indigo text-light border-bottom d-flex p-1">
          <b-form ref="form" @submit="search">
            <b-input-group>
              <b-form-input
                v-model="form.word"
                type="search"
                trim
                placeholder="サイト内検索"
                class="p-1"
              ></b-form-input>
              <b-input-group-append>
                <b-button class="bg-indigo border-light" @click="search"><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </b-list-group-item>
        <b-list-group-item class="border-0 py-1"></b-list-group-item>
        <b-list-group-item disabled class="tag-title squared text-light border-bottom">タグ</b-list-group-item>
        <b-list-group-item
          v-for="tag in settings.tag" :key="tag.id"
          :to="'/tag/'+tag.slug"
              active-class="menu-item-active text-light"
              class="menu-item squared"
        >{{ tag.name }}</b-list-group-item>
      </b-list-group>
    </aside>
  </div>
</div>

<div class="totop">
<transition name="fade">
<b-icon
  icon="arrow-up-square-fill"
  font-scale="2.5"
  variant="pink"
  v-show="toTop"
  v-scroll="onScroll"
  @click="gotoTop"
  style="z-index: 5"
></b-icon>
</transition>
</div>

<footer>
<div id="footer" class="bg-indigo text-light p-0">
  <ul class="d-flex justify-content-center m-0 p-2">
    <li class="mr-3"><router-link :to="{ name: 'privacy' }">プライバシーポリシー</router-link></li>
    <li><router-link :to="{ name: 'contact' }">お問い合わせ</router-link></li>
  </ul>
  <div class="text-center">&copy;Scramblenote.com</div>
</div>
</footer>


</div>
</template>

<script>
  export default {
    props: {
      settings: Object,
    },
    data() {
      return {
        form: {
          word: '',
        },
        leftToggled: false,
        rightToggled: false,
        toTop: false,
        csrf_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        selectedCategory: null,
      }
    },
    watch: {
      $route (to, from) {
//console.log(from.path+"->"+to.path)
        if (!to.path.match(/^\/(category|article).*/)) {
          this.resetCategorySelect()
        }
        this.leftToggled = false
        this.rightToggled = false
        scrollTo(0, 0)
      }
    },
    methods: {
      search(evt) {
        evt.preventDefault()
        this.$router.push({ name: 'search', query: this.form });
      },
      gotoTop() {
        scrollTo({
          top: 0,
          behavior: "smooth"
        })
      },
      onScroll(e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.toTop = top > 100
      },
      response(obj) {
        if (obj.categorySlug != this.selectedCategory) {
          if (this.selectedCategory) {
            document.getElementById('category-'+this.selectedCategory).classList.remove("menu-item-active")
          }
        }
        document.getElementById('category-'+obj.categorySlug).classList.add("menu-item-active")
        this.selectedCategory = obj.categorySlug
      },
      resetCategorySelect() {
        Object.entries(this.settings.category).map(([key, element]) => {
          document.getElementById('category-'+element.slug).classList.remove("menu-item-active")
        })
        this.selectedCategory = null
      },
    }
  }
</script>
<style>
.totop {
  position: fixed;
  bottom: 0;
  right:0;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1.0s
}
.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
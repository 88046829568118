const state = {
    categories: [],
    tags: [],
}

const getters = {
    categories: (state) => state.categories,
    tags: (state) => state.tags,
}

const mutations = {
    setItems(state, items) {
        state.categories = items.categories;
        state.tags = items.tags;
    },
}

const actions = {
    async setItems(context, items) {
        await context.commit('setItems', items);
    }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
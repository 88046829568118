<template>
<div>
      <article>
        <section>

          <b-breadcrumb class="bg-light m-0 px-md-0">
            <b-breadcrumb-item :to="{ name: 'home' }">Home</b-breadcrumb-item>
            <b-breadcrumb-item active>検索：{{ word }}</b-breadcrumb-item>
          </b-breadcrumb>

          <h1 class="border rounded-lg bg-indigo text-light w-100 p-2">検索：{{ word }}</h1>

          <b-card
            no-body
            v-for="item in articles" :key="item.id"
            footer-bg-variant="light"
            footer-border-variant="secondary"
            class="mb-3"
          >

            <template #header>
              <router-link :to="{ name: 'article', params: { slug: item.slug} }" class="text-decoration-none">
                <h3 class="mb-0">
                  {{ item.title }}
                </h3>
              </router-link>
            </template>

            <b-list-group flush>
              <b-list-group-item>
                <router-link :to="{ name: 'category', params: { slug: item.category_slug } }">
                  {{ item.category_name }}
                </router-link>
                <span class="float-right">
                  <b-icon icon="clock"></b-icon>
                  {{ item.updated_at }}
                </span>
              </b-list-group-item>
              <b-list-group-item>{{ item.outline }}</b-list-group-item>
            </b-list-group>
            
            <template #footer>
              <b-button
                v-for="tag in item.tags.split(',')" :key="tag.id"
                size="sm"
                variant="outline-secondary"
                class="mr-1"
                :to="{ name: 'tag', params: { slug: settings.tag[tag].slug } }"
              >
                {{ settings.tag[tag].name }}
              </b-button>
              <b-button :to="{ name: 'article', params:{ slug: item.slug } }" class="float-right bg-primary text-light border-0">詳細</b-button>
            </template>

          </b-card>

          <b-pagination-nav
            v-if="lastPage > 1"
            :value="currentPage"
            :link-gen="linkGen"
            :number-of-pages="lastPage"
            use-router
            @change="getItems"
          ></b-pagination-nav>

        </section>
      </article>
</div>
</template>

<script>
  export default {
    props:{
      settings: Object,
    },
    data() {
      return {
        word: '',
        page: 1,
        articles: [],
        currentPage: 1,
        total: 1,
        perPage: 1,
        lastPage : 1,
      }
    },
    created() {
      if (this.$route.query.page) {
        this.currentPage = this.$route.query.page
      }
      this.word = this.$route.query.word
      this.getItems(this.currentPage)
    },
    watch: {
      $route (to, from) {
        this.word = to.query.word
        this.page = to.query.page
        this.getItems(this.page)
        scrollTo(0, 0)
      },
    },
    methods: {
      getItems(page) {
        axios.get('/api/search', {
          params: {
            word: this.word,
            page: parseInt(page),
          },
        })
        .then((response) => {
          const result = response.data.result
          this.articles = result.data
          this.category = result.category
          this.currentPage = result.current_page
          this.total = result.total
          this.perPage = result.per_page
          this.lastPage = result.last_page
          /* META */
          const type = 'article'
          const title = "[検索] " + this.word + " | " + this.settings.site_title
          const description = "[検索] " + this.word + "の記事一覧"
          const canonical = this.settings.site_url + "/search/" + this.word
          document.title = title;
          document.querySelector("meta[name='description']")
            .setAttribute('content', description)
          document.querySelector("meta[property='og:type']")
            .setAttribute('content', type)
          document.querySelector("meta[property='og:url']")
            .setAttribute('content', canonical)
          document.querySelector("meta[property='og:title']")
            .setAttribute('content', title)
          document.querySelector("meta[property='og:description']")
            .setAttribute('content', description)
          document.querySelector("link[rel='canonical']")
            .setAttribute('href', canonical)
        })
        .catch((error) => {
          console.log(error)
        })
      },
      linkGen(pageNum) {
        // &page=1は非表示に
        let queryParam = { word: this.word }
        if (pageNum > 1) {
          queryParam = { word: this.word, page: pageNum }
        }
        return {
          path: '/search',
          query: queryParam,
        }
      },
    }
  }
</script>

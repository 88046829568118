import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './components/App/Home.vue'
import Category from './components/App/Category.vue'
import Tag from './components/App/Tag.vue'
import Search from './components/App/Search.vue'
import Article from './components/App/Article.vue'
import Contact from './components/App/Contact.vue'
import ContactComplete from './components/App/ContactComplete.vue'
import Privacy from './components/App/Privacy.vue'

import NotFound from './components/NotFound.vue'
Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
        },
        {
            path: '/category/:slug',
            name: 'category',
            component: Category,
        },
        {
            path: '/tag/:slug',
            name: 'tag',
            component: Tag,
        },
        {
            path: '/article/:slug',
            name: 'article',
            component: Article,
        },
        {
            path: '/search',
            name: 'search',
            component: Search,
        },
        {
            path: '/contact',
            name: 'contact',
            component: Contact,
        },
        {
            path: '/contact/complete',
            name: 'contactComplete',
            component: ContactComplete,
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: Privacy,
        },

        {
            path: '*',
            name: '404',
            component: NotFound,
        }
    ],
})

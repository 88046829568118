<template>
<div>
  <article>
    <section>
      <b-breadcrumb class="bg-light m-0 px-md-0">
        <b-breadcrumb-item :to="{ name: 'home' }">Home</b-breadcrumb-item>
        <b-breadcrumb-item :to="{ path: '/category/'+item.category_slug }">{{ item.category_name }}</b-breadcrumb-item>
        <b-breadcrumb-item active>{{ item.title }}</b-breadcrumb-item>
      </b-breadcrumb>
      <div class="markdown-body">
        <h1 class="border rounded-lg bg-indigo text-light w-100 p-2">{{ item.title }}</h1>
        <div class="text-right mb-2">
          <b-icon icon="clock"></b-icon>
            {{ item.updated_at }}
        </div>
        <div>{{ item.outline }}</div>
        <div v-html="item.htmltext"></div>
      </div>
    </section>
  </article>
</div>
</template>

<script>
  export default {
    props:{
      settings: Object,
    },
    data() {
      return {
        item: {},
      }
    },
    mounted() {
      const slug = this.$route.params.slug
      axios.get('/api/article/'+ slug)
      .then((response) => {
        const data = response.data
//console.log(data)
        if (data.result) {
          this.item = data.result
          this.$emit('result', {categorySlug: data.result.category_slug})
          /* META */
          const type = 'article'
          const title = this.item.title + " | " + this.settings.site_title
          const description = this.item.outline
          const canonical = this.settings.site_url + "/article/" + this.item.slug
          document.title = title;
          document.querySelector("meta[name='description']")
            .setAttribute('content', description)
          document.querySelector("meta[property='og:type']")
            .setAttribute('content', type)
          document.querySelector("meta[property='og:url']")
            .setAttribute('content', canonical)
          document.querySelector("meta[property='og:title']")
            .setAttribute('content', title)
          document.querySelector("meta[property='og:description']")
            .setAttribute('content', description)
          document.querySelector("link[rel='canonical']")
            .setAttribute('href', canonical)
        } else {
          this.$router.push('/404')
        }
      })
      .catch((error) => {
        console.log(error)
      })
    },
    methods: {
    }
  }
</script>
